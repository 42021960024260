import '../assets/css/nds.css';

import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Steps from "../components/kfdstep"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyInputMask from "../components/MyInputMask";
import MyCheckbox from "../components/MyCheckbox";
import ButtonLoader from "../components/buttonLoader";

class kfds3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      nds3Data:{dealStructure:'',remitType:'',ProductActivate:[]},
      dealdata:['Participating','Non-Participating'],
      rtypes:['Full Remit','Partial Remit Producer Holds Incentive','Partial Remit Producer Holds Reserve','Net Remit'],
      products2:["KeyFetch $200 Benefit","KeyFetch $400 Benefit","KeyFetch $800 Benefit"],
      products:["Keyfetch","Keyfetch Plus"]
    };
  }

  componentDidMount = async () => {
    const { showLoader, hideLoader } = this.props;
    if(!sessionStorage.ndsver || sessionStorage.ndsver!==process.env.ndsver){
      sessionStorage.clear();
      sessionStorage.ndsver=process.env.ndsver;
    }
    if(!sessionStorage.nds3Data){
        sessionStorage.nds3Data = window.btoa(JSON.stringify(this.state.nds3Data))
    } 

    this.state.nds3Data = JSON.parse(window.atob(sessionStorage.nds3Data))
    let sp = this.state.nds3Data.ProductActivate;
    let ap = this.state.products;
    let ok = [];
    sp.forEach(async (p) => {
      if(ap.includes(p))
        ok.push(p)
    });
    this.state.nds3Data.ProductActivate = ok;
    
    this.setState({nds3Data:JSON.parse(window.atob(sessionStorage.nds3Data))});

    
    
    showLoader();
    hideLoader();
    
  }

  cbchange = async(name,type,bool,value) =>{
    let nds3Data = this.state.nds3Data;
    if(type == "radio"){
        if(bool){
            nds3Data[name] = value;
        } else {
            nds3Data[name] = "";
        }
    } else {
      let indx = nds3Data[name].indexOf(value);
      if(indx>=0){
        if(!bool){
          nds3Data[name].splice(indx, 1);
        }
      } else {
        if(bool){
          nds3Data[name].push(value);
        }
      }
    }
    this.state.nds3Data = nds3Data;
    this.setState({nds3Data:nds3Data});
    //console.log(nds3Data)
    this.enableButton();
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    let nds3Data = this.state.nds3Data;
    //&& nds3Data.remitType
    if(nds3Data.ProductActivate.length)
      this.setState({ canSubmit: true,infoError:"" });
    else
      this.setState({ canSubmit: false,infoError:"" });
  }
  submit = (model) => {
    const { showLoader, hideLoader} = this.props;
    let nds3Data = this.state.nds3Data;
    nds3Data.incentive = model.incentive;
    showLoader();
    sessionStorage.nds3Data = window.btoa(JSON.stringify(nds3Data));
    hideLoader();
    navigate("/kfds5")
  }
  
   
  render() {
    const { nds3Data, canSubmit,dealdata,rtypes,products, issc } = this.state;
    const { buttonLoader} = this.props;
    
   
    return (
      <Layout layoutType="nds" >
        <section className="ndsec kfout">
        <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              <div className="title-text">
                <h2>{'PREFERRED PRODUCTS AND STRUCTURE'}</h2>
              </div>
            </div>

            <div className="body-content mt-40">
              <Formsy ref="nds2Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>

              
                
              <div className="form-container-inline">
                <div className="myform">
                  <div className="h4my">Products to Activate *</div>
                  <div className="check-box-container nln">
                  {
                    products.map((val,ky) => {
                      let tmp = nds3Data.ProductActivate.indexOf(val);
                      return (
                        <div className="form-check" key={"pa"+ky}>
                          <MyCheckbox
                              name={"pa"+ky} id={"pa"+ky} labelClass="check-container big" label={val} 
                              value={tmp>=0?true:false}
                              checkBoxChange={(e) => this.cbchange('ProductActivate', 'cb', e,val)}
                          />
                        </div>
                      );
                    })
                  }
                  
                  </div>
                </div>
              </div>
            
              <div className="form-container-inline">Products may be changed at any time.<br /><br /><br /></div>
                
                {nds3Data.ProductActivate.length>0 && 
                <div className="form-container-inline">
                    <div className="form-label-group error">
                    <MyInputMask name="incentive" id="incentive" placeholder="Incentive Amount($)" mask="9999999" value={nds3Data.incentive} isLabelRequired={true}
                        className="form-control"  validations="isNumeric" validationError="Please enter a valid amount." />
                    </div>
                </div>
                }

                <div className="btn-container">
                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                    </button>
                </div>

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
          
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(kfds3);